/* Results Panel Styling */
#results-panel {
  width: 25%;
  overflow-y: scroll;
  background-color: #f1f1f1;
  border-right: 1px solid #ddd;
}

.place-card-container {
  padding: 0px;
}

.place-card {
  padding: 10px;
  margin: 10px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  list-style: none;
}

.place-card:hover {
  background-color: #f8f9fa;
}

/* App.css */
.navbar {
  padding: 10px;
}

.navbar-content {
  display: flex;
  flex-direction: column; /* Stack title and tagline vertically */
  align-items: flex-start; /* Align items to the left */
}

.navbar-title {
  margin: 0; /* Remove right margin to allow space for tagline */
  font-size: 24px;
  font-weight: bold;
}

.navbar-tagline {
  font-size: 16px; /* Adjust tagline size */
  color: #555; /* Change color for the tagline */
  margin-top: 5px; /* Add some space above the tagline */
}

.navbar-logo {
  font-size: 20px; /* Adjust based on your logo */
}

.main-logo {
  width: 180px;
}

.location-tracking {
  display: inline-flex; /* Adjust this to inline-flex to prevent full-width stretch */
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.location-info {
  margin-top: 20px;
  text-align: center; /* Centering location info */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The container for the slider */
.slider {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-left: 10px;
}

/* Hide the default checkbox */
.slider input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The background of the slider */
.slider .slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* The circle inside the slider */
.slider .slider-round:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Checked slider background */
input:checked + .slider-round {
  background-color: #2196f3;
}

/* Move the circle when checked */
input:checked + .slider-round:before {
  transform: translateX(24px);
}

/* Tooltip */
.tooltip {
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  top: -5px; /* Adjust vertical positioning */
  left: 30px; /* Adjust horizontal positioning */
  z-index: 10;
  max-width: 200px; /* Set a maximum width for the tooltip */
  overflow: hidden; /* Hide overflowing text */
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Disable pointer events on the tooltip itself */
}

.tooltip-icon {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  font-size: 18px;
  color: #007bff;
  position: relative;
}

.tooltip-icon:hover .tooltip {
  opacity: 1; /* Tooltip becomes visible when hovering over the icon */
  pointer-events: auto; /* Enable pointer events when visible */
}

.tooltip-icon:active {
  pointer-events: none; /* Disable pointer events on the icon itself to prevent slider interference */
}

/* Prevent the tooltip icon from moving the slider */
.tooltip-icon {
  position: relative;
  z-index: 100; /* Ensure the tooltip icon is on top of the slider */
}
.headline {
  margin: 20px 0;
  font-weight: bold;
}

.dropdown-select {
  margin: 0 10px;
  padding: 5px;
  font-size: 22px; /* Match the font size of the surrounding text */
  font-weight: bold;
  border: none;
  border-bottom: 2px solid #5a67d8; /* Bottom border for elegance */
  background-color: transparent; /* No background for elegance */
  cursor: pointer;
  width: auto;
}

.dropdown-select:focus {
  outline: none; /* Remove outline on focus */
  border-bottom: 2px solid #4a54e1; /* Change color on focus */
}

.find-restaurant-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #bc002d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.find-restaurant-btn:hover {
  background-color: rgba(188, 0, 45, 0.85);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 0px;
}

/* Restaurant card */
.restaurant-card {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 80vw;
  width: 100%; /* Make it flexible for mobile */
  background-color: white;
  display: flex;
  flex-direction: column;
}

/* Adjusting for larger screens */
@media (min-width: 768px) {
  .restaurant-card {
    max-width: 1000px; /* Wider card for desktop */
  }
}

.carousel-container {
  position: relative;
  max-width: 600px; /* Set a max width for desktop */
  margin: 0 auto; /* Center the carousel */
  overflow: hidden; /* Hide overflow to prevent cut-off */
  margin-top: 40px;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  justify-content: center; /* Centers images horizontally */
  width: 100%;
}

.carousel-image {
  width: 100%; /* Make the image take full width of the container */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Ensures the image covers the area without distortion */
  transition: opacity 0.5s ease; /* Smooth transition effect */
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  color: white;
  font-size: 24px; /* Adjust font size for arrow */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1; /* Make sure buttons are above the image */
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.carousel-prev {
  left: 0; /* Aligns the button to the left */
}

.carousel-next {
  right: 0; /* Aligns the button to the right */
}

.carousel-prev:hover,
.carousel-next:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Darken background on hover */
}

.restaurant-details {
  border: 0px solid #ccc;
  margin-top: 0px;
  margin: 20px auto;
  width: 50%;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  /* Adjust the max-width based on your breakpoint */
  .restaurant-details {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0px;
    width: 80%; /* Optional: make it wider on mobile */
  }
}

.map-link {
  display: inline-block;
  margin-top: 10px;
  color: #5a67d8;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.loading-gif {
  width: 200px; /* Adjust size as needed */
  height: 200px; /* Adjust size as needed */
}

.loading-dots {
  animation: dots 1s steps(5, end) infinite;
}

.loading-dots::after {
  content: "";
  display: inline-block;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80% {
    content: "...";
  }
}

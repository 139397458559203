/* Search Bar Styling */
#search-bar {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  padding: 20px;
  background-color: #f8f9fa; /* Light background for better visibility */
  border: 1px solid #dee2e6;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 400px; /* Set a max width */
  margin: 20px auto; /* Center the search bar */
}

/* Form group styling */
.form-group {
  margin-bottom: 25px; /* Space between groups */
}

/* Label styling */
#search-bar label {
  margin-bottom: 5px; /* Space between label and input */
  font-weight: bold; /* Make labels stand out */
}

/* Dropdown styling */
#place-types {
  width: 100%; /* Full width for dropdown */
  padding: 10px; /* Padding for comfort */
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded corners */
}

/* Slider input */
#radius {
  width: 100%; /* Full width for slider */
}

/* Button styling */
.find-group-btn {
  padding: 10px 20px;
  background-color: #bc002d; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform for hover */
}

.find-group-btn:hover {
  background-color: rgba(188, 0, 45, 0.85);
  transform: translateY(-2px); /* Slight raise on hover */
}
